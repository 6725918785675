import React from "react"
import styled from "styled-components"

const Podium = ({ toggle }) => {
  return (
    <Container>
      <div className="podium second">
        <p className="core-heading core-heading--secondary">bdubya</p>
        <p className="core-text">12,900pts</p>
      </div>
      <div className="podium first">
        <p className="core-heading core-heading--secondary">Tsuto</p>
        <p className="core-text">13,650pts</p>
      </div>
      <div className="podium third">
        <p className="core-heading core-heading--secondary">bryson</p>
        <p className="core-text">12,650pts</p>
      </div>
      <span className="border" />
      <button className="core-button" onClick={() => toggle(true)}>
        Show full leaderboard
      </button>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  text-align: center;
  margin: 80px 0;

  .border {
    position: absolute;
    left: 0;
    margin-top: 237px;
    background-color: #3f434e;
    width: 100%;
    height: 2px;

    @media (min-width: 620px) {
      margin-top: 237px;
    }
  }

  .core-button {
    grid-column: 1 / -1;
  }

  .podium {
    width: 100%;
    align-self: end;

    &::after {
      content: "";
      display: block;
      width: 100%;
      background-color: #3f434e;
    }
  }

  .first {
    &::after {
      border-top: 10px solid #f2d348;
      height: 140px;
    }
  }

  .second {
    &::after {
      border-top: 10px solid #31b5f7;
      height: 90px;
    }
  }

  .third {
    &::after {
      border-top: 10px solid #e8455d;
      height: 40px;
    }
  }
`

export default Podium
