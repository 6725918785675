import React from "react"
import styled from "styled-components"

const Quote = ({ copy }) => {
  return (
    <Wrapper>
      <span>"</span>
      <q>{copy}</q>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  color: #98d358;
  border-top: 2px solid #3f434e;
  border-bottom: 2px solid #3f434e;
  padding: 40px 0;
  margin: 40px 0;

  span {
    font-weight: bold;
    font-size: 48px;
    margin-right: 10px;
    line-height: 1;
  }

  q {
    font-weight: bold;
    font-size: 24px;
  }
`

export default Quote
