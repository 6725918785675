import React, { useState } from "react"
import Layout from "../components/Layout"
import Leaderboard from "../components/Leaderboard"
import TopCta from "../components/TopCta"
import Intro from "../sections/Intro"
import Players from "../sections/Players"
import Challenges from "../sections/Challenges"
import Conclusion from "../sections/Conclusion"
import SEO from "../components/SEO"

const IndexPage = () => {
  const [leaderboardVisible, setLeaderboardVisible] = useState(false)
  return (
    <Layout>
      <SEO title="CFT Spring 2020" />
      <Leaderboard
        visible={leaderboardVisible}
        toggle={setLeaderboardVisible}
      />
      <TopCta />
      <Intro />
      <Players setLeaderboardVisible={setLeaderboardVisible} />
      <Challenges />
      <Conclusion />
    </Layout>
  )
}

export default IndexPage
