import React from "react"
import Footer from "../components/Footer"

const Conclusion = () => (
  <>
    <h3 className="core-heading core-heading--tertiary">
      Thanks for reading...
    </h3>
    <p className="core-text core-text--secondary">
      If you played in the CTF thanks for taking part, we hope you enjoyed your
      experience. If you didn't take part but would like to, you can{" "}
      <a
        href="https://cyber-fasttrack.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        register for CFT summer
      </a>{" "}
      intake now.
    </p>
    <Footer />
  </>
)
export default Conclusion
