import React from "react"
import styled from "styled-components"
import Quote from "../components/Quote"

const Intro = () => {
  return (
    <Container>
      <Logo className="core-heading core-heading--logo core-heading--logo-quiet">
        TOMAHAWQUE
      </Logo>
      <h2 className="core-heading core-heading--secondary">
        Cyber FastTrack Spring 2020
      </h2>
      <p className="core-text core-text--secondary">
        At the end of March Tomhawque hosted Cyber FastTrack Spring 2020. Cyber
        FastTrack is the SANS Institute's free cybersecurity program, designed
        to accelerate college students' entry into the profession without
        costing them a dime. Tomahawque was built to provide CTF organisers with
        a mechanism to deliver challenge briefings, manage event memberships,
        provide a leaderboard, and enable analysis of performance both during,
        and after the event. And we provided all of these for Cyber FastTrack,
        empowering SANS instructors to make informed decisions about who
        deserves the prestigious scholarships. The feedback about the event was
        phenomenal with players of all skill levels heaping praise on both the
        awesome content provided by Cyber FastTrack and the playing experience
        of Tomahawque.
      </p>
      <Quote copy="The format was extremely intuitive and easy to use and the challenges were diverse, and difficulty fit every level. The platform was extremely sleek and I think it's even better than most wargames sites..." />
    </Container>
  )
}

const Container = styled.div``

const Logo = styled.h1`
  margin-bottom: 15px !important;
`

export default Intro
