import React from "react"
import styled from "styled-components"
import CTA from "../images/CTA.svg"

const TopCta = () => {
  return (
    <a
      href="https://cyber-fasttrack.org/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Wrapper>
        <img src={CTA} alt="" />
      </Wrapper>
    </a>
  )
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
`

export default TopCta
