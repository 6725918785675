/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import "../scss/styles.scss"

const Layout = ({ children }) => (
  <Container className="color-background--grey-superdark">
    <Body />
    <Grid>{children}</Grid>
  </Container>
)

const Body = createGlobalStyle`
  html {
    overflow-x: hidden;
  }
  body, html {
    background-color: #1a1b20;
  }
`

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  border-bottom: 2px solid #8a93ab;
`

const Grid = styled.main`
  /* position: relative; */
  width: 320px;
  margin: 0 auto;
  padding: 160px 0 0;

  @media (min-width: 620px) {
    width: 520px;
  }

  @media (min-width: 820px) {
    width: 720px;
  }
`

export default Layout
