import React from "react"
import styled from "styled-components"
import illustration from "../images/comp-home.svg"

const Footer = () => (
  <Container>
    <img src={illustration} alt="" />
  </Container>
)

const Container = styled.div`
  margin-top: 40px;

  img {
    transform: translateY(2px);
  }
`

export default Footer
