import React, { useState } from "react"
import ChallengeList from "../components/ChallengeList"
import { Waypoint } from "react-waypoint"
import styled from "styled-components"

const Challenges = () => {
  const [visible, setVisible] = useState(false)
  return (
    <Container visible={visible}>
      <Waypoint onEnter={() => setVisible(true)}>
        <div>
          <h3 className="core-heading core-heading--tertiary">
            The Challenges
          </h3>
          <p className="core-text core-text--secondary">
            Below are the full list of challenges and their respective stats
            from CFT spring 2020. We will be adding video walkthroughs to these
            so check back in to see the answers to the challenges that alluded
            you.
          </p>
          <ChallengeList />
        </div>
      </Waypoint>
    </Container>
  )
}

const Container = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: translateY(${({ visible }) => (visible ? 0 : 60)}px);
  transition: all 0.5s ease;
`

export default Challenges
