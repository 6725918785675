import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import Quote from "../components/Quote"
import Graph from "../components/Graph"
import Podium from "../components/Podium"
import { Waypoint } from "react-waypoint"
import { useSpring, animated } from "react-spring"
import Arrow from "../images/arrowsvg.svg"

const Players = ({ setLeaderboardVisible }) => {
  const [visible, setVisible] = useState(false)
  const animation = useSpring({
    number: visible ? 3498 : 0,
    config: {
      duration: 500,
    },
  })

  return (
    <>
      <Icon visible={visible} src={Arrow} alt="" />
      <Container visible={visible}>
        <h3 className="core-heading core-heading--tertiary">The Players</h3>
        <Circle visible={visible}>
          <animated.h4 className="core-heading core-heading--primary">
            {animation.number.interpolate(number =>
              Math.floor(number).toLocaleString()
            )}
          </animated.h4>
          <p>Total players</p>
          <span />
        </Circle>
        <Waypoint onEnter={() => setVisible(true)}>
          <div>
            <p className="core-text core-text--secondary">
              Cyber FastTrack Spring 2020 saw 3,498 players from universities
              and colleges across America compete. We saw a wide range of
              different skillsets and experience competing as demonstrated by
              this graphic which shows the total number of different scores
              across the participants. One for all of you graph nerds out
              there...
            </p>
            <Graph />
            <p className="core-text core-text--secondary">
              For a lot of players, this was their first CTF experience, and
              provided validation that they have the ability to test their
              skills against other cyber security focussed students. The
              feedback that we received made it clear that all those who took
              part, no matter their score, gained something valuable by
              participating in this capture the flag event, as part of the Cyber
              FastTrack programme.
            </p>
            <Quote copy="Thank you so much - I thought I didn't have any knowledge about Cyber Security but this CTF motivated me to continue performing at the highest level." />
            <p className="core-text core-text--secondary">
              A massive congratulations to those players who made it on to the
              Tomhawque podium. Tsuto, bdubya and bryson amassed a truly
              staggering 39,200 points between them.
            </p>
            <Podium toggle={setLeaderboardVisible} />
          </div>
        </Waypoint>
      </Container>
    </>
  )
}

const pulse = keyframes`
  0% {
    opacity: 0.2;
    transform: translateY(-5px);
  }

  50% {
    opacity: 1;
    transform: translateY(5px);
  }

  100% {
    opacity: 0.2;
    transform: translateY(-5px);
  }
`

const Icon = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  margin: 80px auto;
  animation: ${pulse} 2s linear infinite;
`

const Container = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: translateY(${({ visible }) => (visible ? 0 : 60)}px);
  transition: all 0.5s ease;
`

const Circle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background-color: #31b5f7;
  border-radius: 100px;
  margin: 0 auto 40px;

  h4,
  p {
    position: relative;
    z-index: 1;
    color: #1a1b20 !important;
    margin: 0;
  }

  span {
    position: relative;
    z-index: 0;
    width: 100vw;
    height: 2px;
    background-color: #31b5f7;
    transform: translateY(-35px)
      ${({ visible }) => (visible ? "scale(1, 1)" : "scale(0, 1)")};
    transition: all 0.5s ease;
  }
`

export default Players
