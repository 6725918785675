import React from "react"
import styled from "styled-components"

const Player = ({ score, name, position, percent }) => (
  <Card>
    <p className="core-text core-text--tertiary">
      <Yellow>{position}</Yellow> - <White>{name}</White>
    </p>
    <p className="core-text core-text--tertiary">
      {percent}% // {score}pts
    </p>
    <Bar className="core-bar core-bar--bottom">
      <Completed className="completed" width={percent} />
    </Bar>
  </Card>
)

const Card = styled.div`
  margin-bottom: 40px;
`

const Bar = styled.div`
  background-color: ${({ color }) => color};
  position: relative !important;
`

const Completed = styled.div`
  width: ${({ width }) => width}%;
  background-color: ${({ color }) => color} !important;
`

const Yellow = styled.span`
  color: #f2d348;
`

const White = styled.span`
  color: #ffffff;
`

export default Player
