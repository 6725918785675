import React from "react"
import leaderboard from "../leaderboard.json"
import styled from "styled-components"
import Player from "./Player"

const oridinalSuffix = n => {
  let s = ["th", "st", "nd", "rd"],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

const max = 43

const Leaderboard = ({ visible, toggle }) => (
  <>
    <Container visible={visible}>
      <h2 className="core-heading core-heading--quinary">Leaderboard</h2>
      {leaderboard.map((player, index) => (
        <Player
          key={index}
          score={player.points}
          name={player.display_name}
          position={oridinalSuffix(index + 1)}
          percent={Math.round((player.correct_attempts / max) * 100)}
        />
      ))}
    </Container>
    <Overlay onClick={() => toggle(false)} visible={visible} />
  </>
)

const Container = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 320px;
  height: 100vh;
  background-color: #1a1b20;
  overflow-y: scroll;
  padding: 20px;
  border-left: 2px solid #3f434e;
  ${({ visible }) =>
    visible
      ? `opacity: 1; transform: translateX(0vw);`
      : `opacity: 0; transform: translateX(100vw);`}
  transition: all 0.3s ease-in-out;

  h2 {
    margin-bottom: 40px !important;
  }
`

const Overlay = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #1a1b20;
  pointer-events: all;
  ${({ visible }) =>
    visible
      ? `opacity: 0.8; pointer-events: all;`
      : `opacity: 0; pointer-events: none;`}
  transition: all 0.3s ease-in-out;
`

export default Leaderboard
