import React, { useState } from "react"
import scores from "../scores.json"
import styled from "styled-components"
import { Waypoint } from "react-waypoint"

const Graph = () => {
  const columns = [
    "2,000",
    "4,000",
    "6,000",
    "8,000",
    "10,000",
    "12,000",
    "14,000",
  ]
  const rows = ["350", "300", "250", "200", "150", "100", "50"]

  const [visible, setVisible] = useState(false)

  return (
    <Container>
      <div className="label label--y">
        <h5 className="core-heading core-heading--senary">No. of players</h5>
      </div>
      <div className="label label--x">
        <Waypoint onEnter={() => setVisible(true)} />
        <h5 className="core-heading core-heading--senary">Score</h5>
      </div>
      {rows.map((row, index) => (
        <Row key={index}>
          <p className="core-text core-text--tertiary">{row}</p>
        </Row>
      ))}
      {columns.map((column, index) => (
        <Column key={index} left={index}>
          <p className="core-text core-text--tertiary">{column}</p>
        </Column>
      ))}
      {scores.map(({ score, players }, index) => (
        <Score
          visible={visible}
          key={index}
          index={index}
          x={(score / 14000) * 100}
          y={(players / 350) * 100}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  left: 0;
  width: 100%;
  margin: 60px 0 80px;
  border-bottom: 1px solid #3f434e;

  @media (max-width: 819px) {
    margin: 60px 0 100px;
  }

  .label {
    position: absolute;

    &--y {
      left: 0px;
      top: -20px;
    }

    &--x {
      bottom: -60px;
      right: 0;
      left: 0;
      width: 100%;
      text-align: center;
      margin: 0 auto;

      @media (max-width: 819px) {
        bottom: -90px;
      }
    }
  }
`

const Row = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 70px;
  border-top: 1px solid #3f434e;

  p {
    position: absolute;
    text-align: right;
    left: -40px;
    top: -10px;

    @media (max-width: 819px) {
      transform: rotate(90deg);
      left: -25px;
    }
  }
`

const Column = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: ${props => `calc((100% / 7) * ${props.left})`};
  width: calc(100% / 7);
  height: 100%;

  &:nth-child(even) {
    background-color: #0d0e10;
  }

  p {
    position: absolute;
    bottom: -40px;
    right: -20px;

    @media (max-width: 819px) {
      transform: rotate(90deg);
      text-align: left;
      bottom: -60px;
    }
  }
`

const Score = styled.div`
  position: absolute;
  z-index: 5;
  bottom: ${({ y }) => y}%;
  left: ${({ x }) => x}%;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  border: 1px solid #31b5f7;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: scale(${({ visible }) => (visible ? 1 : 0)});
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.76, 0.04, 0.05, 4);
  transition-delay: ${({ index }) => `${index / 100}s`};
`

export default Graph
