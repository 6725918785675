import React, { useState } from "react"
import challenges from "../challenges.json"
import styled from "styled-components"
import { Waypoint } from "react-waypoint"
import { useSpring, animated, config } from "react-spring"

const returnHex = percent => {
  if (percent > 75) {
    return "#98d358"
  } else if (percent > 50) {
    return "#31b5f7"
  } else if (percent > 25) {
    return "#f2d348"
  } else {
    return "#e8455d"
  }
}

const ChallengeCard = ({
  index,
  short_title,
  points,
  attempts,
  correct,
  hit_rate,
}) => {
  const [visible, setVisible] = useState(false)

  const animation = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? "translateY(0px)" : "translateY(40px)",
    config: config.stiff,
    delay: () => ((index + 1) % 3 === 0 ? 100 : (index + 1) % 3 === 2 ? 50 : 0),
  })

  return (
    <Waypoint onEnter={() => setVisible(true)}>
      <Card style={animation} color={returnHex(hit_rate)} key={short_title}>
        <div className="header">
          <p className="core-text core-text--primary">{short_title}</p>
          <p className="core-text">{points}pts</p>
        </div>
        <div className="percentage">
          <p className="core-heading core-heading--tertiary">
            <span>{Math.round(hit_rate * 10) / 10}%</span>
          </p>
          <p className="core-text core-text--secondary">success rate</p>
        </div>

        <div className="attempts">
          <p className="core-heading core-heading--quarternary">
            <span>{correct}</span>/{attempts}
          </p>
          <p className="core-text core-text--secondary">correct attempts</p>
        </div>
        <div className="footer"></div>
      </Card>
    </Waypoint>
  )
}

const ChallengeList = () => {
  return (
    <Grid>
      {challenges.map(
        ({ short_title, points, attempts, correct, hit_rate }, index) => (
          <ChallengeCard
            key={index}
            index={index}
            short_title={short_title}
            points={points}
            attempts={attempts}
            correct={correct}
            hit_rate={hit_rate}
          />
        )
      )}
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  margin: 60px 0;

  @media (min-width: 620px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 820px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Card = styled(animated.div)`
  border: 2px solid ${props => props.color};
  padding: 20px;

  span {
    color: ${props => props.color};
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .percentage,
  .attempts {
    p {
      margin-bottom: 0;
    }
    margin-bottom: 20px;
  }
`

export default ChallengeList
